import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';

const Icon = ({ mode }) => {
  const classNames = `NavigationTrigger__icon NavigationTrigger__icon--${mode}`;

  return (
    <span className={classNames}>
      <span />
      <span />
      <span />
    </span>
  );
};

Icon.propTypes = {
  mode: PropTypes.oneOf(['toggle', 'open', 'close']).isRequired,
};

export default Icon;
