import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bannerTypes, metaTypes } from '../proptypes';
import Lightbox from 'react-lightbox-component';
// import VideoTrigger from '../../../components/VideoTrigger';
import './style.sass';


function render360(data) {
  if (!data) {
    return null;
  }

  if(Array.isArray(data)) {
    return data.map(({ link, label, ...props }) => {
      const isInternal = props.target === '_self';
      const Component = isInternal ? Link : 'a';

      return (
        <Component
          key={label}
          className={`Button Button--outline Banner__action Button--${label.toLowerCase()}`}
          target="_blank"
          rel="noopener noreferrer"
          href={link}
          to={link}
          {...props}>
          {label}
        </Component>
      )
    });
  }

  return (
    <a
      className="Button Button--outline Banner__action"
      target="_blank"
      rel="noopener noreferrer"
      href={data}>
      Tour 360º
    </a>
  );
};

const renderFotos = (idx, image, toggleLightbox) => {
  if(idx !== 1)
    return null;

  return (
    <a key={idx} href="#fotos" class="Button Button--outline" onClick={toggleLightbox.bind(null, idx)} >Fotos</a>
  );
};


const Banner = ({ banner, meta }) => {
  const small = banner.title.length < 25 ? 'Banner__title--small' : '';
  const medium = banner.title.length >= 25 && banner.title.length < 50 ? 'Banner__title--medium' : '';
  const large = banner.title.length >= 50 ? 'Banner__title--large' : '';
  const classNames = ['Banner__title', small, medium, large];

  return (
      <section className="Banner" style={{ backgroundImage: `url(${banner.background})` }}>
      <h2 className={classNames.join(' ')}>{banner.title}</h2>
      <div className="Banner__actions">
      {render360(meta['360'])}
      {meta['fotos'] ? (<Lightbox images={meta['fotos']} renderImageFunc={renderFotos} />) : null }
      {banner['selos']? (banner.selos.map((selo) => ( <img src={selo.imagem} width="100%" alt="selo" /> ))):null}
      </div>
    </section>
  );
};

Banner.propTypes = {
  banner: PropTypes.shape(bannerTypes).isRequired,
  meta: PropTypes.shape(metaTypes).isRequired,
};

export default Banner;
