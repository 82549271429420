import React from "react";
import { Helmet } from "react-helmet";
import "./style.sass";

import Phone from "./assets/phone.svg";
import Site from "./assets/site.svg";

export default function Denuncias() {
  return (
    <main className="denuncias">
      <Helmet>
        <title>Canal de denúncias</title>
      </Helmet>
      <div className="denuncias__header">
        <h2 className="denuncias__title">Canal de denúncias</h2>
      </div>
      <div className="denuncias__container">
        <div className="denuncias__info">
          <div className="denuncias__description">
            <p>
              A <strong>Fribal</strong> está comprometida em manter um ambiente
              de trabalho <strong>íntegro</strong> e{" "}
              <strong>livre de irregularidades.</strong> Por isso,
              disponibilizamos para nossos colaboradores e parceiros de negócios
              um canal de comunicação inteligente, 100% sigiloso, seguro, que
              serve para detectar e combater problemas como fraudes, bullying,
              assédio moral/sexual, entre muitas outras irregularidades que não
              estejam de acordo com o nosso{" "}
              <strong>Manual de Ética e Conduta</strong>, as{" "}
              <strong>leis</strong> ou com nossos <strong>valores.</strong>
            </p>
            <p>
              Esse canal é gerenciado por uma empresa terceirizada e totalmente
              imparcial chamada Contato Seguro que garante o anonimato do
              manifestante sempre que assim desejar. O canal está sempre
              disponível 24h por dia, durante todos os dias do ano, incluindo os
              feriados
            </p>
          </div>
          <h3 className="denuncias__subtitle about__title">
            Quem pode denunciar
          </h3>
          <div className="denuncias__description">
            <p>
              Colaboradores, gestores, fornecedores, parceiros, etc. Ou seja,
              todos os envolvidos direta ou indiretamente com a nossa empresa.
            </p>
            <p>
              Sempre que perceber algo “estranho” acontecendo em seu ambiente
              profissional, denuncie sem medo. Você estará contribuindo para o
              desenvolvimento da nossa organização e zelando por um futuro
              melhor.
            </p>
          </div>
        </div>
        <div className="denuncias__contato">
          <h3 className="denuncias__subtitle">
            Viu alguma
            <br />
            irregularidade?
            <br />
            <strong>Denuncie</strong>
          </h3>
          <ul className="denuncias__list">
            <li className="denuncias__item">
              <span className="item__title">Ligue:</span>
              <span className="item__description">
                <img src={Phone} alt="icone de telefone" />
                <a className="item__description--phone" href="tel:08005171228">
                  0800 517 1228
                </a>
              </span>
            </li>
            <li className="denuncias__item">
              <span className="item__title">Acesse:</span>
              <span className="item__description">
                <img src={Site} alt="icone de site" />
                <a
                  className="item__description--site"
                  href="https://www.contatoseguro.com.br/pt/fribal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.contatoseguro.com.br/pt/fribal
                </a>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </main>
  );
}
