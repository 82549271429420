import React, { useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';
import Passo from './Passo';
import './style.sass';
import conteudo from '../../content/pastoAoPrato';


function getRightBackgroud(backgrounds) {
  const screenSizes = Object.keys(backgrounds).sort();
  const { innerWidth } = window;
  let background = backgrounds['0'];

  for(let i = 0; i < screenSizes.length; i++) {
    const screen = parseInt(screenSizes[i]);

    if(screen >= innerWidth) {
      background = backgrounds[screen];
    }
  }

  return background;
}

function Home() {
  const [, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const backgroundUpdater = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', backgroundUpdater);

    return () => {
      window.removeEventListener('resize', backgroundUpdater);
    };
  }, []);

  const ordem = [
    'pecuaria',
    'industria',
    'distribuicao',
    'canais',
    'marcas',
    'varejo',
  ];

  return (
    <main className="Home">
      <Helmet>
        <title>FRIBAL - Especialistas em Carne</title>
      </Helmet>

      {ordem.map(key => {
        const { home } = conteudo[key];

        return (
          <Passo
            key={key}
            to={home.link}
            titulo={home.title}
            imagem={getRightBackgroud(home.background)}
            about={home.about}
          />
        )
      })}

      <h2 className="Home__title">Do Pasto ao Prato</h2>
      
    </main>
  );
};

export default Home;
