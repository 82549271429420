/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './style.sass';

const themes = [
  'default',
  'primary',
  'secondary',
  'outline',
  'primary-outline',
  'secondary-outline',
];

const Button = ({
  theme, children, ...props
}) => (
  <button
    className={`Button Button--${theme}`}
    {...props}
  >
    {children}
  </button>
);

Button.propTypes = {
  theme: PropTypes.oneOf(themes),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  children: PropTypes.string.isRequired,
};

Button.defaultProps = {
  theme: 'default',
  type: 'button',
};

Button.Link = ({
  theme, children, to, ...props
}) => {
  if (to.startsWith('http') || to.startsWith('tel:')) {
    return (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        className={`Button Button--${theme}`}
        {...props}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      to={to}
      className={`Button Button--${theme}`}
      {...props}
    >
      {children}
    </Link>
  );
};

Button.Link.propTypes = {
  to: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(themes),
  children: PropTypes.string.isRequired,
};

Button.Link.defaultProps = {
  theme: 'default',
};


export default Button;
