import PropTypes from 'prop-types';

export const metaTypes = {
  prev: PropTypes.string,
  next: PropTypes.string,
  title: PropTypes.string,
  position: PropTypes.string,
  // 360: PropTypes.string,
  360: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export const bannerTypes = {
  background: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export const headlineTypes = {
  tag: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export const sectionTypes = {
  title: PropTypes.string.isRequired,
  descriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default {
  banner: bannerTypes,
  meta: metaTypes,
  headline: headlineTypes,
  section: sectionTypes,
};
