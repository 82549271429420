import React from 'react';
import ModalVideo from 'react-modal-video';

function VideoModal({ videoId, isOpen, setOpen }) {
  return (
    <ModalVideo
      channel="youtube"
      videoId={videoId}
      isOpen={isOpen}
      youtube={{ autoplay: 1, mute: 0 }}
      onClose={() => setOpen(false)}
    />
  );
}

export default VideoModal;
