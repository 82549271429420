import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import ReCAPTCHA from 'react-google-recaptcha';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import endpoints from '../../../content/endpoints';
import states from './states';
import './style.sass';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const validate = (values) => {
  const errors = {};
  const required = [
    'nome',
    'telefone',
    'cidade',
    'estado',
    'departamento',
    'mensagem',
  ];

  if (!values.email) {
    errors.email = 'Email é um campo obrigatório';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Digite um email válido';
  }

  if (!values.telefone && values.length < 13) {
    errors.telefone = 'Telefone é um campo obrigatório';
  }

  if (!values.captcha) {
    errors.captcha = 'Captcha inválido.';
  }

  required.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'É um campo obrigatório';
    }
  });

  return errors;
};

const onSubmit =
  (setLoading) =>
  (values, { resetForm }) => {
    setLoading(true);

    fetch(endpoints.contato.salvar, {
      method: 'POST',
      body: JSON.stringify(values),
    })
      .then((res) => {
        const isSucessful = res.status === 200;

        if (isSucessful) resetForm();

        Swal.fire(
          isSucessful
            ? {
                title: 'Sucesso!',
                text: 'Obrigado! Entraremos em contato em breve.',
                icon: 'success',
                confirmButtonColor: '#ed1d24',
              }
            : {
                title: 'Falha!',
                text: 'Ocorreu algum problema. Não podemos enviar o seu email.',
                icon: 'error',
                confirmButtonColor: '#ed1d24',
              }
        );
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

const Form = () => {
  const query = useQuery();
  const [isLoading, setLoading] = useState(false);

  const [assuntos, setAssuntos] = useState([]);
  useEffect(() => {
    fetch(endpoints.contato.assunto, { method: 'GET' })
      .then((res) => res.json())
      .then((data) => {
        setAssuntos(data);
      })
      .catch((err) => console.log(err));
  }, []);

  const formManager = useFormik({
    initialValues: {
      nome: '',
      email: '',
      telefone: '',
      cidade: '',
      estado: '',
      departamento: query.get('dp') || '',
      mensagem: '',
      captcha: '',
    },
    validate,
    onSubmit: onSubmit(setLoading),
    validateOnBlur: true,
    validateOnChange: false,
  });

  return (
    <form className="ContactForm" onSubmit={formManager.handleSubmit}>
      <div className="ContactForm__input--full">
        <Input
          name="nome"
          label="Nome"
          value={formManager.values.nome}
          error={formManager.errors.nome}
          onChange={formManager.handleChange}
          onBlur={formManager.handleBlur}
        />
      </div>

      <Input
        name="email"
        label="Email"
        type="email"
        value={formManager.values.email}
        error={formManager.errors.email}
        onChange={formManager.handleChange}
        onBlur={formManager.handleBlur}
      />
      <Input
        name="telefone"
        label="Telefone"
        value={formManager.values.telefone}
        error={formManager.errors.telefone}
        onChange={formManager.handleChange}
        onBlur={formManager.handleBlur}
      />

      <div className="ContactForm__group ContactForm__group--1-3 ContactForm__input--full">
        <Input
          name="cidade"
          label="Cidade"
          value={formManager.values.cidade}
          error={formManager.errors.cidade}
          onChange={formManager.handleChange}
          onBlur={formManager.handleBlur}
        />

        <Input.Select
          name="estado"
          label="Estado"
          value={formManager.values.estado}
          error={formManager.errors.estado}
          onChange={formManager.handleChange}
          onBlur={formManager.handleBlur}>
          <option key={'disabledStaate'} disabled></option>
          {states.map((state) => (
            <option key={state.value} value={state.value}>
              {state.label}
            </option>
          ))}
        </Input.Select>
      </div>

      <div className="ContactForm__input--full">
        <Input.Select
          name="departamento"
          label="Assunto"
          value={formManager.values.departamento}
          error={formManager.errors.departamento}
          onChange={formManager.handleChange}
          onBlur={formManager.handleBlur}>
          <option key={'disabledDepartament'} disabled></option>
          {assuntos.map((assunto, i) => (
            <option key={i} value={assunto.id}>
              {assunto.nome}
            </option>
          ))}
        </Input.Select>
      </div>

      <div className="ContactForm__input--full">
        <Input.Area
          name="mensagem"
          label="Mensagem"
          onChange={formManager.handleChange}
          onBlur={formManager.handleBlur}
          value={formManager.values.mensagem}
          error={formManager.errors.mensagem}
        />
      </div>
      <ReCAPTCHA
        sitekey="6LeWhcwZAAAAAD8ogfIdmYsNnvPTLUfB-8pAU2aY"
        onChange={(v) => formManager.setFieldValue('captcha', v)}
      />
      <div className="ContactForm__buttons">
        {!isLoading ? (
          <Button type="submit" theme="primary-outline">
            Enviar
          </Button>
        ) : (
          <span className="ContactForm__spinner">Enviando...</span>
        )}
      </div>
    </form>
  );
};

export default Form;
