import React from 'react';
import './style.sass';

const links = [
  {
    type: 'instagram',
    href: 'https://instagram.com/fribal_',
    label: 'Instagram',
  },
  {
    type: 'facebook',
    href: 'https://www.facebook.com/fribalespecialistasemcarne/',
    label: 'Facebook',
  },
  {
    type: 'youtube',
    href: 'https://www.youtube.com/channel/UC3PAmZe6SVjsowCG_Y_uWOw',
    label: 'Youtube',
  },
];

const Social = ({ style }) => {
  return (<div className="Social" style={style}>
    {
      links.map((social) => (
        <a
          key={social.type}
          className={[
            `Social__link Social__link--${social.type}`
          ].join(' ')}
          href={social.href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {social.label}
        </a>
      ))
    }
  </div>);
}

export default Social;
