import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css/normalize.css';
import './fonts/stylesheet.css';
import 'sweetalert2/src/sweetalert2.scss';
import 'react-lightbox-component/build/css/index.css';
import './styles/main.sass';
import App from './pages/router';
import * as serviceWorker from './serviceWorker';

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
