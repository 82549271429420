import React, { useState, useRef, useEffect } from 'react';
import lottie from 'lottie-web';
import './style.sass';
import animationData from './data'

const Splashscreen = ({setLoadSplash}) => {
  const container = useRef(null);
  const [isAnimationDone, setAnimationDone] = useState(false);
  useEffect(() => {
    if(container.current) {
      const animation = lottie.loadAnimation({
        container: container.current, // the dom element that will contain the animation
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: animationData
      });
      animation.addEventListener('complete', () => {
          setAnimationDone(true)
          setLoadSplash(false)
        }
      );
    }
    // eslint-disable-next-line
  }, [container]);

  if (window.location.href.indexOf('local') !== -1) {
    return null;
  }

  return (
    <div className={`Splashscreen Splashscreen--is-done-${isAnimationDone}`}>
      <div className="Splashscreen__container" ref={container}></div>
    </div>
  )
};

// disable temporarily
// export default () => null
export default Splashscreen;
