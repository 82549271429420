import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../components/Button';
import './style.sass';

const CallToAction = ({
  title,
  description,
  action,
  actionLabel,
  background,
}) => (
  <section className="CallToAction" style={{ backgroundImage: `url(${background})` }}>
    <h3 className="CallToAction__title">{title}</h3>
    <div className="CallToAction__description">
      <p>{description}</p>
    </div>
    <Button.Link to={action} theme="outline" target="_blank">{actionLabel}</Button.Link>
  </section>
);

export const types = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  actionLabel: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
};

CallToAction.propTypes = types;

export default CallToAction;
