const { origin } = window.location;
const api = '/manager/api';

export default {
  contato: {
    assunto: `${origin}${api}/contato/assunto`,
    salvar: `${origin}${api}/contato/salvar`,
  },
  lojas: `${origin}${api}/loja`,
  cidades: `${origin}${api}/cidade`,
};
