import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CallToAction from './CallToAction';
import Lightbox from 'react-lightbox-component';
import { sectionTypes } from '../proptypes';
import './style.sass';

const renderParagraphs = (paragraphs) => {

  if(paragraphs.img) {
    return (
      <img
        src={paragraphs.img}
        key={paragraphs.id}
        alt=""
        title=""
        className="img--responsive Section__image"
      />
    );
  }

  if (typeof paragraphs === 'string') {
    return (
      <p key={paragraphs} className="Section__paragraph">
        {paragraphs}
      </p>
    );
  }

  return paragraphs;
};

// eslint-disable-next-line react/prop-types
const renderImage = (idx, image, toggleLightbox) => {
  return (
    <img
      src={image.src}
      alt=""
      title={image.title}
      className="img--responsive"
      key={idx}
      onClick={toggleLightbox.bind(null, idx)}
    />
  );
};

const Section = ({
  image1, image2, title, descriptions, reverse,
}) => (
  <section className={['Section', reverse ? 'Section--reverse' : ''].join(' ')}>
    <div className="Section__content">
      <h4 className="Section__title">
        {title.href && title.href.startsWith('http') ? <a href={title.href} target="_blank" rel="noopener noreferrer">{title.text}</a> : null}
        {title.href && !title.href.startsWith('http') ? <Link to={title.href}>{title.text}</Link> : null}
        {!title.text ? title : null}
      </h4>
      <div className="Section__description">
        {descriptions.href && descriptions.href.startsWith('http') ? <a href={descriptions.href} target="_blank" rel="noopener noreferrer">{descriptions.text.map(renderParagraphs)}</a> : null}
        {descriptions.href && !descriptions.href.startsWith('http') ? <Link to={descriptions.href}>{descriptions.text.map(renderParagraphs)}</Link> : null}
        {!descriptions.text ? descriptions.map(renderParagraphs) : null}
      </div>
    </div>

    <div className="Section__image">
      <Lightbox
        images={[
          { src: image1, title: '', description: '' },
          { src: image2, title: '', description: '' }
        ]}
        renderImageFunc={renderImage}
        renderDescriptionFunc={() => null}
      />
    </div>
  </section>
);

Section.propTypes = {
  ...sectionTypes,
  reverse: PropTypes.bool,
};

Section.defaultProps = {
  reverse: false,
};

Section.CallToAction = CallToAction;

export default Section;
