import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import './style.sass';

const NavigationTrigger = ({
  children, modeLabels, mode, style, ...props
}) => {
  const modeFunctions = {
    toggle: () => props.setOpen(!props.isOpen),
    open: () => props.setOpen(true),
    close: () => props.setOpen(false),
  };

  return (
    <button
      className="NavigationTrigger"
      type="button"
      onClick={() => modeFunctions[mode]()}
      style={style}
    >
      <Icon mode={mode} />

      {children || modeLabels[mode]}
    </button>
  );
};

NavigationTrigger.propTypes = {
  mode: PropTypes.oneOf(['toggle', 'open', 'close']),
  modeLabels: PropTypes.shape({
    toggle: PropTypes.string,
    open: PropTypes.string,
    close: PropTypes.string,
  }),
  setOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.string,
};

NavigationTrigger.defaultProps = {
  mode: 'toggle',
  children: 'menu',
  modeLabels: {
    toggle: 'Menu',
    open: 'Abrir',
    close: 'Fechar',
  },
};

export default NavigationTrigger;
