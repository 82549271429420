import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import types from './proptypes';
import Tabs from './Tabs';
import Banner from './Banner';
import Section from './Section';
import './style.sass';

// eslint-disable-next-line react/prop-types
const renderSection = ({ id, image, title, descriptions, image1, image2 }, index) => (
  <Section
    key={id}
    image1={image1}
    image2={image2}
    title={title}
    descriptions={descriptions}
    reverse={(index + 1) % 2 === 0}
  />
);

const Content = ({ content, className }) => {

  return (
    <main className={`Content ${className}`}>
      <Helmet>
        <title>{`${content.meta.title} | FRIBAL - Especialistas em Carne`}</title>
      </Helmet>
      <Tabs next={content.meta.next} />
      <Banner banner={content.banner} meta={content.meta} />
      <div className="Section__container">
        {content.sections.map(renderSection)}
      </div>
    </main>
  );
};


Content.propTypes = {
  content: PropTypes.shape({
    banner: PropTypes.shape(types.banner),
    meta: PropTypes.shape(types.meta),
    headlines: PropTypes.arrayOf(PropTypes.shape(types.headline)),
  }).isRequired,
};

export default Content;
