import React from 'react';
import PropTypes from 'prop-types';
import Navigation from '../Navigation';
import Logo from '../Logo';
import Social from '../Social';
import './style.sass';

const Header = ({ size, setMenuOpen, isOpen, setVideoOpen }) => {
const classNames = ['Header', `Header--${size}`];

  return (
    <header className={classNames.join(' ')}>
      <div className="Header__logo">
        <Logo size={size} />
      </div>

      <Navigation isOpen={isOpen} setOpen={setMenuOpen} setVideoOpen={setVideoOpen} />

      <Navigation.Trigger
        mode="open"

        isOpen={isOpen}
        setOpen={setMenuOpen}
        style={{ order: 1 }} />

      <Social style={{ order: 3 }}/>
    </header>
  );
};

Header.propTypes = {
  size: PropTypes.oneOf([
    'small',
    'normal',
    'large',
  ]),
  setMenuOpen: PropTypes.func,
  isOpen: PropTypes.bool,
};

Header.defaultProps = {
  size: 'small',
  setMenuOpen: () => [],
  isOpen: false,
};

export default Header;
