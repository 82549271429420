import React, { useState, useEffect, useRef, useCallback } from 'react';
import './style.css';

const InputSearch = ({ data, inputValue, setInputValue, disable }) => {
  const [options, setOptions] = useState([]);
  const [viewOptions, setViewOptions] = useState(false);
  const [label, setLabel] = useState('');

  const ref = useRef();

  let suggestList;
  let validator;

  const updateOptions = () => {
    validator = new RegExp(`[a-zA-ZÀ-ü]?${inputValue}[a-zA-ZÀ-ü]?`, 'gi');
    suggestList = data.filter(
      ({ nome, tipo, endereco }) =>
        nome.match(validator) ||
        tipo.match(validator) ||
        endereco.match(validator)
    );

    setOptions(suggestList);
  };

  useEffect(() => {
    options.length > 0 && inputValue !== options[0] && setViewOptions(true);
  }, [options, inputValue]);

  const selectedFromList = (item) => {
    setInputValue(item);
    // setInputValue(target.innerHTML);

    setOptions([]);
    setViewOptions(false);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if (inputValue?.length > 0) {
      updateOptions();
    } else {
      setViewOptions(false);
      setOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const handleInputChange = useCallback(
    (e) => {
      setInputValue(e.target.value);
    },
    [setInputValue]
  );
  const ListOfSuggestions = () => {
    useEffect(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps

      const checkIfClickedOutside = (e) => {
        if (viewOptions && ref.current && !ref.current.contains(e.target)) {
          setViewOptions(false);
        }
      };

      document.addEventListener('mousedown', checkIfClickedOutside);

      return () => {
        document.removeEventListener('mousedown', checkIfClickedOutside);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewOptions]);

    return (
      <div ref={ref} className="optionsList" hidden={!viewOptions}>
        <ul className="menuOptionsCities">
          {options.map((item, i) => (
            <li
              className="listItems"
              value={item.nome}
              key={i}
              onClick={(e) => {
                selectedFromList(item);
                setLabel(`${item.tipo} - ${item.nome}`);
              }}>
              <p className="storeName">
                {item.tipo.toUpperCase()} - {item.nome.toUpperCase()}
              </p>
              <p className="storeAddress">{item.endereco.toLowerCase()}</p>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="input-search-container">
      <input
        className="input-typeahead"
        value={label}
        placeholder="Digite a localidade"
        type="text"
        onChange={(e) => {
          handleInputChange(e);
          setLabel(e.target.value);
        }}
        style={
          !viewOptions
            ? { borderRadius: '0.375rem' }
            : {
                borderRadius: ' 0.375rem  0.375rem 0 0',
                borderBottom: 0,
                borderColor: 'red',
              }
        }
      />
      <ListOfSuggestions />
    </div>
  );
};

export default InputSearch;
