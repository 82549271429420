import React from 'react';
import { Link } from 'react-router-dom';
import './style.sass';

const activeConverter = (path) => ((window.location.pathname === path) ? 'Tabs__link Tabs__link--active' : 'Tabs__link');

const resetScroll = () => { document.querySelector('.Content').scrollTo({ top: 0, behavior: 'auto' }); };

const Tabs = () => (
  <nav className="Tabs">
    <div className="Tabs__container">
      <Link onClick={resetScroll} className="Tabs__inicio" to="/">Do pasto ao prato</Link>
      <Link onClick={resetScroll} className={activeConverter('/pecuaria')} to="/pecuaria">Pecuária</Link>
      <Link onClick={resetScroll} className={activeConverter('/industria')} to="/industria">Indústria</Link>
      <Link onClick={resetScroll} className={activeConverter('/distribuicao')} to="/distribuicao">Distribuição</Link>
      <Link onClick={resetScroll} className={activeConverter('/canais')} to="/canais">Canais de venda</Link>
      <Link onClick={resetScroll} className={activeConverter('/marcas')} to="/marcas">Marcas</Link>
      <Link onClick={resetScroll} className={activeConverter('/varejo')} to="/varejo">Varejo</Link>
    </div>
  </nav>
);

export default Tabs;
