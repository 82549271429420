import React from 'react';
import './style.sass';
import { Link } from 'react-router-dom';
import Logo from '../../components/Logo';

const ErrorNotFound = () =>{
    return (
        <main className="Notfound">
            <Logo />
            <h2 className='Notfound__title'>
                <span>ERRO</span>
                <strong>404</strong>
            </h2>
            <h3 className='Notfound__subtitle'>Página não encontrada</h3>
            <span className='Notfound__description'>Esta página não existe ou o link pode estar quebrado.</span>
            <Link className='Button Button--primary-outline-cb Notfound__action' to="/">IR PARA A HOME</Link>
        </main>
    );
}

export default ErrorNotFound;