import React from 'react';
import { Helmet } from 'react-helmet';
import VideoTrigger from '../../components/VideoTrigger';
import './style.sass';
import imagemBanner from './assets/about.jpg';

const About = () => (
  <main className="About">
    <Helmet>
      <title>Sobre nós | FRIBAL - Especialistas em Carne</title>
    </Helmet>
    <h2 className="About__title">Sobre nós</h2>
    <div className="About__header">
      <h3 className="About__subtitle">Especialistas em Carne</h3>
      <VideoTrigger theme="primary" />
      <div className="About__image">
        <img src={imagemBanner} alt="industria" className="img--responsive" />
      </div>
    </div>

    <section className="About__content">
      <div className="About__description">
        <p>
          O padrão de qualidade em tudo que produzimos transformou a FRIBAL em uma marca forte,
          arrojada e de grande credibilidade, porque atuamos com excelência.
        </p>
        <p>
          Temos vasta experiência no ramo de carnes e conquistamos um padrão exclusivo de qualidade
          porque cuidamos diretamente de todo o manejo dos produtos, do pasto ao prato.
        </p>
        <p>
          Com a garantia de origem e a qualidade do nosso produto final,
          conquistamos o mercado nacional e internacional, mantendo fluxo significativo
          de exportação de carne bovina. E continuamos em expansão.
        </p>
        <p>
          Todos os produtos FRIBAL são atestados pelo Ministério da
          Agricultura por meio do Serviço de Inspeção Federal (SIF).
          Garantir qualidade e confiança em tudo que fazemos é uma de nossas prioridades!
        </p>
      </div>
      <div className="About__mission">
        <h4 className="About__subtitle">Missão</h4>
        <p>
          Garantir produtos e serviços com qualidade, superando as expectativas de nossos clientes,
          colaboradores e acionistas, sempre com ética e responsabilidade social.
        </p>
      </div>
      <div className="About__vision">
        <h4 className="About__subtitle">Visão</h4>
        <p>
          Ser a maior e melhor empresa do ramo de carnes do Brasil,
          indo 100% de satisfação entre os clientes internos e externos.
        </p>
      </div>
    </section>
  </main>
);

export default About;
