import React from 'react';
import PropTypes from 'prop-types';

import 'react-modal-video/scss/modal-video.scss';
import './style.sass';


const VideoTrigger = ({
  href,
  theme,
  classNames,
  className,
  videoId,
  children,
  onClick,
  setOpen,
}) => {
  return (
    <>
      <a
        href={href}
        target="_blank"
        className={[className, 'VideoTrigger', `VideoTrigger--${theme}`].concat(classNames).join(' ')}
        rel="noopener noreferrer"
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
          onClick();
        }}
      >
        {children}
      </a>
    </>
  );
};

VideoTrigger.propTypes = {
  href: PropTypes.string,
  theme: PropTypes.oneOf(['default', 'primary', 'navigation']),
  videoId: PropTypes.string,
  children: PropTypes.string,
  onClick: PropTypes.func,
};

VideoTrigger.defaultProps = {
  href: 'https://www.youtube.com/watch?v=aLxlLO0enhA',
  videoId: 'aLxlLO0enhA',
  theme: 'default',
  children: 'Assistir Vídeo',
  onClick: () => [],
};

export default VideoTrigger;
