import React, { useEffect, useRef, useState } from 'react';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import styleMap from './assets/style.json';
import './style.sass';

import pinFribal from './assets/logo-fribal.png';
import pinEmporio from './assets/logo-emporio.png';
import pinMercearia from './assets/logo-mercearia.png';
import pinCenterbox from './assets/marcas/centerbox.png';
import pinGuara from './assets/marcas/guara.png';
import pinPinheiro from './assets/marcas/pinheiro.png';
import pinMedeiros from './assets/marcas/medeiros.png';
import pinBrasileiro from './assets/marcas/brasileiro.png';
import pinCarnauba from './assets/marcas/carnauba.png';
import pinCometa from './assets/marcas/cometa.png';
import pinFortali from './assets/marcas/fortali.png';
import pinFreitas from './assets/marcas/freitas.png';
import pinHipermarket from './assets/marcas/hipermarket.png';
import pinIsmael from './assets/marcas/ismael.png';
import pinNidobox from './assets/marcas/nidobox.png';
import pinPontes from './assets/marcas/pontes.png';
import pinPovo from './assets/marcas/povo.png';

const renderPins = (pins, selectedStore = {}) =>
  pins.map((pin) => {
    let pinIcon = null;
    const isEmporio = pin.tipo.toLowerCase().includes('emporio');
    const isMercearia = pin.tipo.toLowerCase().includes('mercearia');
    const isCenterbox = pin.tipo.toLowerCase().includes('center');
    const isGuara =
      pin.nome.toLowerCase().includes('guara') &&
      pin.estado.toLowerCase() === 'ce';
    const isPinheiro = pin.tipo.toLowerCase().includes('pinheiro');
    const isMedeiros = pin.tipo.toLowerCase().includes('medeiros');
    const isBrasileiro = pin.tipo.toLowerCase().includes('brasileiro');
    const isCarnauba = pin.tipo.toLowerCase().includes('carnauba');
    const isCometa = pin.tipo.toLowerCase().includes('cometa');
    const isFortali = pin.tipo.toLowerCase().includes('fortali');
    const isFreitas = pin.tipo.toLowerCase().includes('freitas');
    const isHipermarket = pin.tipo.toLowerCase().includes('hipermarket');
    const isIsmael = pin.tipo.toLowerCase().includes('ismael');
    const isNidobox = pin.tipo.toLowerCase().includes('nidobox');
    const isPontes = pin.tipo.toLowerCase().includes('pontes');
    const isPovo = pin.tipo.toLowerCase().includes('povo');

    //TODO: TRANSFORMAR A LISTA DE ICONES EM UMA ESTRUTURA DINAMICA E REUTILIZAVEL.
    if (isEmporio) {
      pinIcon = pinEmporio;
    } else if (isMercearia) {
      pinIcon = pinMercearia;
    } else if (isCenterbox) {
      pinIcon = pinCenterbox;
    } else if (isGuara) {
      pinIcon = pinGuara;
    } else if (isPinheiro) {
      pinIcon = pinPinheiro;
    } else if (isMedeiros) {
      pinIcon = pinMedeiros;
    } else if (isBrasileiro) {
      pinIcon = pinBrasileiro;
    } else if (isCarnauba) {
      pinIcon = pinCarnauba;
    } else if (isCometa) {
      pinIcon = pinCometa;
    } else if (isFortali) {
      pinIcon = pinFortali;
    } else if (isFreitas) {
      pinIcon = pinFreitas;
    } else if (isHipermarket) {
      pinIcon = pinHipermarket;
    } else if (isIsmael) {
      pinIcon = pinIsmael;
    } else if (isNidobox) {
      pinIcon = pinNidobox;
    } else if (isPontes) {
      pinIcon = pinPontes;
    } else if (isPovo) {
      pinIcon = pinPovo;
    } else {
      pinIcon = pinFribal;
    }

    return (
      <Marker
        key={pin.endereco}
        position={{ lat: parseFloat(pin.lat), lng: parseFloat(pin.lng) }}
        icon={pinIcon}
        onClick={pin.selectStore}
      />
    );
  });

const Map = withScriptjs(
  withGoogleMap(({ store, stores, city, selectedStore }) => {
    const mapRef = useRef(null);

    const [zoom, SetZoom] = useState(12);

    useEffect(() => {
      if (store.lng) {
        SetZoom(15);
      }
    }, [store]);

    useEffect(() => {
      if (mapRef) {
        mapRef.current.panTo({
          lat: parseFloat(city.lat),
          lng: parseFloat(city.lng),
        });
      }
    }, [mapRef, city]);

    useEffect(() => {
      if (mapRef && selectedStore.lat) {
        mapRef.current.panTo({
          //move o mapa
          lat: parseFloat(selectedStore.lat),
          lng: parseFloat(selectedStore.lng),
        });
      }
    }, [selectedStore]);

    return (
      <GoogleMap
        ref={mapRef}
        zoom={zoom}
        defaultCenter={{ lat: parseFloat(city.lat), lng: parseFloat(city.lng) }}
        defaultOptions={{
          styles: styleMap,
          disableDefaultUI: true,
        }}>
        {renderPins(stores, selectedStore)}
      </GoogleMap>
    );
  })
);

Map.defaultProps = {
  googleMapURL:
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyAK8Np23E9IjWnBxUfN-JKFHE9z_IfbYTY&v=3.exp&libraries=geometry,drawing,places',
  loadingElement: <div style={{ height: '100%' }} />,
  containerElement: <div className="Map" />,
  mapElement: <div style={{ height: '100%' }} />,
  stores: [],
};

export default Map;
