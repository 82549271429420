import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/Button';
import './style.sass';

const phoneSpecialCharacters = /( )|(\()|(\))|(-)/g;

const StoreData = ({ store, removeStore }) => (
  <div className="StoreData">
    <span className="StoreData__title">{store.nome}</span>
    <address className="StoreData__address">{store.endereco}</address>
    {store.link_mapa ? (<Button.Link to={store.link_mapa} theme="primary-outline">Ver mapa</Button.Link>) : null}

    {store.telefone ? (<a href={`tel:+55${store.telefone.replace(phoneSpecialCharacters, '')}`} className="StoreData__phone">{store.telefone}</a>) : null}
    {store.telefone ? (<Button.Link to={`tel:+55${store.telefone.replace(phoneSpecialCharacters, '')}`} theme="primary-outline">Ligar agora</Button.Link>) : null}

    <Button onClick={removeStore} theme="primary-outline">X</Button>
  </div>
);

StoreData.propTypes = {
  store: PropTypes.shape({
    tipo: PropTypes.string,
    nome: PropTypes.string,
    endereco: PropTypes.string,
    telefone: PropTypes.string,
    link_mapa: PropTypes.string,
    link_tour: PropTypes.string,
  }),
  removeStore: PropTypes.func,
};

StoreData.defaultProps = {
  store: {
    tipo: '',
    nome: '',
    endereco: '',
    telefone: '',
    link_mapa: '',
    link_tour: '',
  },
  removeStore: () => []
};

export default StoreData;
