import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.sass';

const Logo = ({ title, Element, size }) => (
  <Element className={['Logo', `Logo--${size}`].join(' ')}>
    <Link to="/">{title}</Link>
  </Element>
);

Logo.propTypes = {
  title: PropTypes.string,
  size: PropTypes.oneOf(['normal', 'small', 'nav']),
  Element: PropTypes.string,
};

Logo.defaultProps = {
  title: 'FRIBAL',
  Element: 'h1',
  size: 'normal',
};

export default Logo;
